import { Toast } from 'bootstrap';

export const getToastScheme = (type) => {
    let scheme = ''
    switch (type) {
        case 'info': {
            scheme = 'bg-info'
            break;
        }
        case 'success': {
            scheme = 'bg-success'
            break;
        }
        case 'warning': {
            scheme = 'bg-warning'
            break;
        }
        case 'error': {
            scheme = 'bg-danger'
            break;
        }
        default: {
            scheme = 'bg-info'
            break;
        }
    }
    return scheme
}

export const getToastTitle = (type) => {
    let title = ''
    switch (type) {
        case 'info': {
            title = 'Info'
            break;
        }
        case 'success': {
            title = 'Success'
            break;
        }
        case 'warning': {
            title = 'Warning'
            break;
        }
        case 'error': {
            title = 'Error'
            break;
        }
        default: {
            title = 'Info'
            break;
        }
    }
    return title
}

export const makeToast = ({ message = '', type = 'info' }) => {
    const headerElem = document.querySelector('.toast-header')
    const titleElem = document.querySelector('.toast-title')
    const messageElem = document.querySelector('.toast-message')

    var toastElList = [].slice.call(document.querySelectorAll('.toast'))
    var toastList = toastElList.map(function (toastEl) {
        // Creates an array of toasts (it only initializes them)
        return new Toast(toastEl) // No need for options; use the default options
    });

    const title = getToastTitle(type)
    const scheme = getToastScheme(type)

    headerElem.classList.add(scheme)
    titleElem.innerHTML = title
    messageElem.innerHTML = message
    toastList?.at(0).show()

    setTimeout(() => {
        headerElem.classList.remove(scheme)
        titleElem.innerHTML = ''
        messageElem.innerHTML = ''
    }, 6000)
}